.logo {
  --glow-color: rgb(217, 176, 255);
  --glow-spread-color: rgba(191, 123, 255, 0.781);
  --enhanced-glow-color: rgb(231, 206, 255);
  --btn-color: rgb(100, 61, 136);

  &.active {
    animation: halation 2s ease-in-out alternate infinite;
    svg {
      @apply fill-zinc-50;
    }
  }

  @keyframes halation {
    0% {
      box-shadow: 0 0 0em 0em var(--glow-color),
        0 0 0em 0em var(--glow-spread-color),
        inset 0 0 0em 0em var(--glow-color);
    }
    100% {
      box-shadow: 0 0 5em 0em var(--glow-color),
        0 0 0em 0em var(--glow-spread-color),
        inset 0 0 1em 0em var(--glow-color);
    }
  }

  @keyframes ripple {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(2);
      border-color: transparent;
    }
  }
}
