.loader {
  @apply w-full h-full;
  display: flex;
  justify-content: center;
  align-items: center;
  &.mobile {
    height: calc(100vh - 63px);
    margin-top: -32px;
  }
  span {
    @apply text-slate-200;
    font-size: 20px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    animation: blur 3s linear infinite;
    line-height: 20px;
    transition: all 0.5s;
    letter-spacing: 0.3em;
  }
}

@keyframes blur {
  0%,
  90% {
    filter: blur(0);
  }

  50% {
    filter: blur(10px);
  }
}
