.root {
  @apply relative z-10 p-4 xl:pl-12 xl:pt-12 lg:pl-8 lg:pt-8 text-xl text-gray-500 font-medium md:block;
  //@apply bg-red-900 md:bg-transparent;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.2),
    rgba(23, 23, 22, 0)
  );
  &.mobile {
    background: none;
    &.active {
      @apply bg-slate-900;
    }
  }
  font-family: "OPPOSans";
  .nav {
    //@apply hidden;
  }
}
