.root {
  /*width: 840px;*/
  @apply px-4 md:w-3/5 relative z-10 2xl:pt-12 2xl:pr-12 lg:pt-8 lg:pr-8 flex flex-col items-end md:overflow-auto;
  min-height: calc(100vh - 63px);
  min-width: 100px;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.5),
    rgba(23, 23, 22, 0)
  );
  &.mobile {
    background: none;
    //height: 300px;
    //border: 1px solid red;
  }
  /*background: linear-gradient(*/
  /*  to left,*/
  /*  rgba(23, 23, 22, 0.35) 3.33%,*/
  /*  rgba(23, 23, 22, 0.2905) 48.78%,*/
  /*  rgba(23, 23, 22, 0) 99.08%*/
  /*);*/
  /*transform: matrix(-1, 0, 0, 1, 0, 0);*/
}
