.root {
  @apply w-full rounded rounded-xl pb-8;
  :global {
    .ProseMirror {
      font-family: "OPPOSans";
      //color: rgba(255, 255, 255, 0.9);
      //font-size: 16px;
      //line-height: 21px;
      @apply text-lg text-gray-200;
      > * + * {
        //margin-top: 16px;
        @apply mt-6;
      }
      &:focus {
        outline: none;
      }
      h1 {
        font-style: normal;
        //font-weight: 400;
        //font-size: 36px;
        //line-height: 47px;
        @apply text-4xl font-bold;
      }
      > *:nth-child(2) {
        //margin-top: 12px;
        @apply mt-3;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
      }
      // taskList
      ul[data-type="taskList"] {
        list-style: none;
        padding: 0;

        p {
          margin: 0;
        }

        li {
          @apply flex mb-2;
          > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            user-select: none;
          }

          > div {
            flex: 1 1 auto;
          }
        }
      }
      // blockquote
      blockquote {
        @apply mt-6 mb-6 ml-8;
        border-left: 3px solid rgba(255, 255, 255, 0.3);
        padding-left: 1rem;
      }
      // figcaption
      figcaption {
        @apply flex justify-start items-center italic mt-1 text-gray-400 text-base;
      }
    }
  }
  &.editor {
    :global {
      .ProseMirror {
        @apply bg-zinc-800 p-8 rounded;
        min-height: calc(100vh - 4rem - 2rem);
        //h1 {
        //  @apply text-gray-50;
        //}
      }
    }
  }
}
