@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body,
#root {
  align-content: center;
  @apply bg-slate-900 h-full;
}

#gtx-trans {
  display: none;
}
