.root {
  @apply h-full flex justify-center items-center z-50;
  .text {
    @apply text-white font-bold text-2xl md:text-7xl leading-tight;
    --gradient-highlight-spread: 15%;
    background-image: linear-gradient(
        100deg,
        transparent calc(50% - var(--gradient-highlight-spread)),
        rgb(241 245 249),
        transparent calc(50% + var(--gradient-highlight-spread))
      ),
      linear-gradient(-28deg, rgb(15 23 42 / 1), rgb(15 23 42 / 1));
    background-size: 200% 100%, 100% 100%;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-position: 140% 50%, 50% 50%;
    //animation: light 6s linear infinite alternate;
    animation: text-light 3s linear infinite;
  }
}

@keyframes text-light {
  from {
    background-position: 150% 50%, 50% 50%;
  }
  to {
    background-position: -50% 50%, 50% 50%;
  }
}
